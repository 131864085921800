import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "dropdownMenu",
    "weightField",
    "heartRateField",
    "spo2Field",
    "temperatureField",
    "intakeDrinkField",
    "outputField",
    "glucoseField",
    "activityField",
    "bloodPressureFields",
    "dailyCheckInField",
    "swellingField",
    "incentiveSpirometerField",
    "submitButton",
    "validationErrorMessage",
    "systolicBloodPressure",
    "diastolicBloodPressure"
  ];

  static fields = {
    weight: "weightField",
    heart_rate: "heartRateField",
    spo2: "spo2Field",
    temperature: "temperatureField",
    intake_drink: "intakeDrinkField",
    output: "outputField",
    glucose: "glucoseField",
    activity: "activityField",
    blood_pressure: "bloodPressureFields",
    daily_check_in: "dailyCheckInField",
    swelling: "swellingField",
    incentive_spirometer: "incentiveSpirometerField",
  }

  connect() {
    this.dropdownMenuTarget.addEventListener(
      "change",
      this.updateForm.bind(this)
    );
    this.submitButtonTarget.disabled = true;

    this.hideAllFields();
  }

  updateForm() {
    this.hideAllFields();

    // show the selected field
    this[`${this.constructor.fields[this.dropdownMenuTarget.value]}Target`].style.display = "block";

    this.submitButtonTarget.disabled = true;
  }

  validate(event) {
    const input = event.target;
    const min = parseFloat(input.dataset.min);
    const max = parseFloat(input.dataset.max);
    const value = parseFloat(input.value);

    let isValid = true;

    if (value < min || value > max || isNaN(value)) {
      isValid = false;
      this.validationErrorMessageTarget.textContent = `Value must be between ${min} and ${max}`;
      this.validationErrorMessageTarget.classList.remove("d-none");
    } else {
      this.validationErrorMessageTarget.textContent = "";
      this.validationErrorMessageTarget.classList.add("d-none");
    }

    if (this.dropdownMenuTarget.value === "blood_pressure") {
      const systolicInput = this.systolicBloodPressureTarget;
      const diastolicInput = this.diastolicBloodPressureTarget;

      const systolicMin = parseFloat(systolicInput.dataset.min);
      const systolicMax = parseFloat(systolicInput.dataset.max);
      const systolicValue = parseFloat(systolicInput.value);

      const diastolicMin = parseFloat(diastolicInput.dataset.min);
      const diastolicMax = parseFloat(diastolicInput.dataset.max);
      const diastolicValue = parseFloat(diastolicInput.value);

      if (systolicValue < systolicMin || systolicValue > systolicMax || diastolicValue < diastolicMin || diastolicValue > diastolicMax) {
        isValid = false;
        this.validationErrorMessageTarget.textContent = `Systolic value must be between ${systolicMin} and ${systolicMax}, and Diastolic value must be between ${diastolicMin} and ${diastolicMax}`;
        this.validationErrorMessageTarget.classList.remove("d-none");
      } else if (isNaN(systolicValue) || isNaN(diastolicValue)) {
        isValid = false;
        this.validationErrorMessageTarget.textContent = "Please enter a valid number for both systolic and diastolic blood pressure";
        this.validationErrorMessageTarget.classList.remove("d-none");
      } else {
        this.validationErrorMessageTarget.textContent = "";
        this.validationErrorMessageTarget.classList.add("d-none");
      }
    }

    this.submitButtonTarget.disabled = !isValid;
  }

  hideAllFields() {
    // iterate over the fields object and hide all fields
    Object.values(this.constructor.fields).forEach(field => {
      this[`${field}Target`].style.display = "none";
    });
  }
}
